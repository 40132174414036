<template>
  <keep-alive include="SegmentListView">
    <router-view :loading="isLoading" />
  </keep-alive>
</template>

<script>
export default {
  name: 'SegmentLayout',

  data() {
    return {
      isLoading: false,
    };
  },

  created() {
    this.fetchSegments();
  },

  methods: {
    async fetchSegments() {
      this.loading = true;
      try {
        await this.$store.dispatch('setAllSegments');
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.$store.dispatch('errorToast', e.message);
      }
    },
  },
};
</script>
