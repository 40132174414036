<template>
  <div class="scroll-wrapper" v-bar="{ scrollThrottle: 30 }">
    <div class="scroll-content">
      <div class="container-fluid">

        <div class="row">
          <div class="col">
            <BackToFlightsLink />
          </div>
        </div>

        <div class="row">
          <div class="col-6 offset-3">
            <h1>New Segment</h1>
          </div>
        </div>

        <div class="row">
          <section class="col-5 offset-3">
            <form @submit.prevent="saveSegment">
              <div class="input-group" :class="{'invalid': $v.segment.name.$error }">
                <b-input
                  type="text"
                  class="bordered"
                  v-focus
                  v-model.trim="$v.segment.name.$model"
                  lazy-formatter
                  id="new-segment-name-input"
                  ref="new-segment-name-input"
                  data-tour="segment-name"
                  placeholder="Segment Name"
                  aria-label="Segment Name"/>
                  <Error>
                    <span v-if="!$v.segment.name.required">Segment name is a required field</span>
                    <span v-if="!$v.segment.name.minLength">
                      Segment name must be at least
                      {{ $v.segment.name.$params.minLength.min}} characters
                    </span>
                    <span v-if="!$v.segment.name.unique">
                      You already have a saved segment with the name
                    </span>
                  </Error>
              </div>

              <AudienceTypeahead
                @audience-updated="updateAudience"
                @validate="(val) => isAudienceValid = val"
                :focus-on-mount="false"
                :allow-saved-segments="false"
                :emails-prop="segment.emails"
                data-tour="segment-participants"
                :user-ids-prop="segment.userIds"
                :user-limit="participantLimitOnActiveTeam"
              />

              <div class="row">
                <div class="col d-flex justify-content-end mt-5 mb-5">
                  <Button
                    class="tertiary"
                    @click.native="cancelSegment">
                    cancel
                  </Button>
                  <Button
                    class="primary"
                    type="submit"
                    :is-loading="isLoading"
                    :disabled="$v.$invalid || !isAudienceValid">
                      Save
                    </Button>
                </div>
              </div>
            </form>
          </section>
          <aside class="col-2 offset-1 tip">
            <Tip>
              Type or copy and paste email addresses (separated by commas) of
              the people you want to invite to this flight.
            </Tip>
          </aside>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';

import AudienceTypeahead from '@/components/AudienceTypeahead.vue';

export default {
  name: 'SegmentNewView',
  mixins: [validationMixin],

  components: {
    AudienceTypeahead,
  },

  data() {
    return {
      isLoading: false,
      isAudienceValid: false,

      segment: {
        criteria: null,
        name: '',
        emails: [],
        userIds: [],
        isSaved: true,
      },
    };
  },

  validations: {
    segment: {
      name: {
        required,
        minLength: minLength(2),
        unique(value) {
          const lowerName = value.toLowerCase();
          return lowerName.length === 0 ? true
            : !this.mySavedSegments.some((seg) => seg.name.toLowerCase() === lowerName);
        },
      },
    },
  },

  created() {
    this.segment.userIds.push(this.loggedInUser.id);
  },
  methods: {
    updateAudience(data) {
      this.segment.emails = data.emails;
      this.segment.userIds = data.userIds;
    },

    async saveSegment() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('createSegment', this.segment);
        this.$store.dispatch('successToast', 'Segment created!');
        this.$router.push('/segments');
      } catch (err) {
        this.$store.dispatch('errorToast', 'There was a problem saving the segment.');
      } finally {
        this.isLoading = false;
      }
    },

    cancelSegment() {
      this.$router.push('/segments');
    },
    setFocusOnName() {
      this.$nextTick(() => {
        this.$refs['new-segment-name-input'].focus();
      });
    },
  },
  computed: {
    ...mapGetters(['loggedInUser', 'mySavedSegments', 'activeTeam', 'participantLimitOnActiveTeam']),
    nameState() {
      const lowerName = this.segment.name.toLowerCase();
      return this.segment.name.length === 0 ? null
        : !this.mySavedSegments.some((seg) => seg.name.toLowerCase() === lowerName);
    },
    disableSubmit() {
      return this.pending || (this.segment.isSaved && !this.nameState)
      || this.segment.userIds.length + this.segment.emails.length < 1;
    },
  },
};
</script>

<style lang="less" scoped>
h1 {
  margin: 2rem 0 0 0;
}

.tip {
  margin-top: 6rem;
}
</style>
