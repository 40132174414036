<template>
  <div class="container-fluid h-100 d-flex flex-column">
    <section class="mt-8 h-75">
      <div class="row">
        <div class="col-8 offset-2 d-flex flex-column">

          <section class="row justify-content-between align-items-end">
            <div class="col-12 col-xl-3 d-flex align-items-center">
              <h1>Segments</h1>
              <h2 class="ml-4 font-weight-light">{{ mySegments.length }}</h2>
            </div>
            <div class="col-6 col-xl-3 ml-auto">
              <div class="input-group input-search">
                <b-input type="search"
                v-model.trim="searchString"
                :state="searchState"
                lazy-formatter
                id="user-search"
                class="bordered iconed"/>
                <label for="user-search">
                  <icon name="search" />
                  <span>Search</span>
                </label>
                <b v-if="searchString"
                  class="green flex-shrink-0 search-count">
                  {{ filteredSegments.length }}
                </b>
              </div>
            </div>
            <div class="col-6 col-xl-3 d-flex justify-content-end">
              <router-link
                :to="{ name: 'segment-new' }"
                :disabled="!isActiveSubscription"
                data-tour="create-segment"
                tag="button"
                class="primary"
              >Create new segment</router-link>
            </div>
            <Tip
              v-if="!isActiveSubscription"
              class="expired-subscription"
              :show-title="false">
              Reactivate your subscription to create segments.
            </Tip>
          </section>
        </div>
      </div>

      <div class="row mt-5 h-100">
        <div class="col-8 offset-2 h-100 d-flex flex-column">
          <div class="row flex-grow-1 h-100 pb-4 px-4">
            <div
              class="container-fluid h-100 table-wrapper"
              v-bar="{ scrollThrottle: 30 }">
              <div class="scroll-content">
                <LoadingBalloonAnimation width="200" height="125" v-if="loading"/>

                <b-table :fields="tableFields" :items="filteredSegments" v-else>
                  <template v-slot:cell(name)="data">
                    <button data-tour="see-segment" @click="showSegment(data.item.id)">
                      {{data.item.name}}
                    </button>
                  </template>
                  <template v-slot:cell(creator)="data">
                    {{userNameById(data.item.createdUserId)}}
                  </template>
                  <template v-slot:cell(privacy)>
                    <img src="/images/icons/admin.svg" class="d-inline-block mr-3" alt="" />
                    Private
                  </template>
                  <template v-slot:cell(actions)="data">
                    <b-dropdown
                      data-tour="segment-actions"
                      no-caret
                      right
                      menu-class="actions-dropdown">
                      <template slot="button-content">
                        <icon
                          name="more_actions"
                          aria-label="More Action"
                          class="d-block hover-scale py-2 pl-4 pr-3"
                        />
                      </template>
                      <b-dropdown-item-button @click="removeFromList(data.item.id)">
                        <icon name="remove" width="7" height="7"/>
                        <span>Remove from list</span>
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <b-modal
      id="segment-modal"
      ref="segmentModal"
      hide-footer
      lazy
      centered
      @cancel="hideSegment"
      header-class="d-flex align-items-center px-5 border-bottom-0"
      bodyClass="tan p-4">
      <div v-if="selectedSegmentId" class="row">
        <div class="col px-5 pb-4">
          <h4 class="tan my-3">
            Matching Users ({{segmentById(selectedSegmentId).users.length}})
          </h4>
          <p v-for="userId in segmentById(selectedSegmentId).users" :key="userId">
            {{userNameById(userId)}}
          </p>
          <hr class="col-12 my-4" />
          <h4 class="tan my-3">Privacy</h4>
          <p>Private</p>
        </div>
      </div>
      <h3 slot="modal-title" v-if="selectedSegmentId"
        class="tan mt-4">{{segmentById(selectedSegmentId).name}}</h3>
      <button slot="modal-header-close">
        <img src="/images/icons/x.svg" width="11" class="hover-scale" alt="Close segment modal" />
      </button>
  </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SegmentListView',

  props: ['loading'],

  data() {
    return {
      searchString: '',
      tableFields: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'creator',
          label: 'Creator',
        },
        {
          key: 'privacy',
          label: 'Privacy',
        },
        {
          key: 'actions',
          label: 'Actions',
          class: 'text-right',
        },
      ],
      selectedSegmentId: null,
    };
  },

  methods: {
    showSegment(segmentId) {
      this.selectedSegmentId = segmentId;
      this.$refs.segmentModal.show();
    },
    hideSegment() {
      this.$refs.segmentModal.hide();
      this.selectedSegmentId = null;
    },
    async removeFromList(segmentId) {
      await this.$store.dispatch('removeSegment', segmentId);
      this.$store.dispatch('successToast', 'Segment has been removed');
    },
  },

  computed: {
    ...mapGetters({
      mySegments: 'mySavedSegments',
      segmentById: 'segmentById',
      userNameById: 'userNameById',
      isActiveSubscription: 'isActiveSubscription',
    }),

    filteredSegments() {
      const filtered = this.mySegments;

      // filter if search is in use
      if (this.searchState) {
        const query = this.searchString.toLowerCase();
        return filtered.filter((segment) => [segment.name,
          this.userNameById(segment.createdUserId)]
          .some((value) => value.toLowerCase().includes(query)));
      }
      return filtered;
    },

    searchState() {
      return this.searchString.length === 0 ? null : true;
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .dropdown-toggle {
  width: auto;
}

#user-search {
  min-height: 3.5rem;

  // Hide 'x' icon when input has focus in Safari, Chrome and IE
  &::-webkit-search-cancel-button { -webkit-appearance: none }
  &::-ms-clear, &::-ms-reveal {
    display: none;
    width : 0;
    height: 0;
  }
}

.search-count {
  right: 10px;
  bottom: 10px;
  position: absolute;
  background-color: @pink;
  padding: .5rem;
}

.expired-subscription {
  position: absolute;
  left: 100%;
  top: 2rem;
  min-width: 10rem;
}
</style>
